import { SessionProvider } from "next-auth/react";
import { Analytics } from "@vercel/analytics/react";
import localFont from "@next/font/local";
import Layout from "../components/Layout";
import Router from "next/router";
import NProgress from "nprogress";
import { Toaster } from "react-hot-toast";
import "../styles/globals.css";
import "nprogress/nprogress.css";

const akrobat = localFont({
  src: [
    {
      path: "../public/assets/fonts/Akrobat-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/assets/fonts/Akrobat-ExtraLight.woff2",
      weight: "100",
      style: "normal",
    },
  ],
  variable: "--font-akrobat",
});
NProgress.configure({ parent: "#maincontainer" });
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  return (
    <SessionProvider session={session}>
      <main className={`${akrobat.variable} font-sans`}>
        <Toaster />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </main>
    </SessionProvider>
  );
}
