import React, { useState, useEffect, Fragment } from "react";
import Link from "next/link";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useSession, signIn, signOut } from "next-auth/react";
import Logosvg from "../Molecules/Logosvg";
import Image from "next/image";
import { useScrollPosition } from "../../hooks/useScrollPosition";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EmptyAvatar() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-6 h-6"
      >
        <path
          fillRule="evenodd"
          d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
}

const navigation = [
  { name: "About", href: "/#about", current: true },
  { name: "Our Clients", href: "/#our-clients", current: false },
  { name: "How We Work", href: "/#how-we-work", current: false },
  { name: "Advantages", href: "/#advantages", current: false },
  { name: "Contact", href: "/#contact", current: false },
];

const profile = [
  { name: "Dashboard", href: "/dashboard", current: false, protected: true },
  { name: "Your Profile", href: "#", current: false, protected: false },
  { name: "Settings", href: "#", current: false, protected: false },
];

export default function Navbar() {
  const scrollPosition = useScrollPosition();
  const { data: session, status } = useSession();
  const loading = status === "loading";

  return (
    <Disclosure
      as="nav"
      className={classNames(
        scrollPosition > 0 ? "shadow-md" : "shadow-none",
        "sticky top-0 backdrop-blur z-20 transition-shadow bg-white"
      )}
    >
      {({ open }) => (
        <>
          <div className="z-40 w-full flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white">
            <div className="max-w-8xl mx-auto">
              <div className="py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 lg:mx-0">
                <div className="relative flex items-center justify-between mx-4">
                  {/* Mobile Menu Section */}
                  <div className="md:hidden w-32">
                    <div className="absolute p-2 inset-y-0 left-0 flex items-center md:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-[#1aa1b7] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                  {/* Logo Section  */}
                  <div className="md:flex-none px-2 w-32 text-slate-900 ">
                    <Link
                      href="/"
                      className="md:flex-none overflow-hidden md:w-auto flex h-6"
                    >
                      <span className="sr-only">Lighttree Homepage</span>
                      <Logosvg />
                    </Link>
                  </div>
                  {/* Large Menu Section */}
                  <div className="hidden flex-grow text-slate-900 md:flex justify-center ">
                    {navigation.map((menuItem) => (
                      <Link
                        href={menuItem.href}
                        key={menuItem.name}
                        className="transition ease-in-out px-4 text-2xl text-slate-900 hover:text-yellow-400 duration-200 font-akrobat font-medium"
                      >
                        {menuItem.name}
                      </Link>
                    ))}
                  </div>
                  {/* Auth Section */}
                  <div className="md:flex-none w-32 inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:pr-0 justify-end pr-4">
                    {/* Profile dropdown */}
                    {!session && loading ? (
                      <div className="donut"></div>
                    ) : (
                      <>
                        {session?.user ? (
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button className="flex rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#1aa1b7]">
                                <span className="sr-only">Open user menu</span>
                                {session.user.image ? (
                                  <Image
                                    className="h-8 w-8 rounded-full"
                                    src={session.user.image}
                                    alt="avatar"
                                    width={32}
                                    height={32}
                                  />
                                ) : (
                                  <EmptyAvatar />
                                )}
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {profile.map((profileItem) => (
                                  <Menu.Item key={profileItem.name}>
                                    {({ active }) => (
                                      <Link
                                        href={profileItem.href}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                        key={profileItem.name}
                                      >
                                        {profileItem.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "flex px-4 py-2 text-sm text-gray-700 w-full"
                                      )}
                                      onClick={() => signOut()}
                                    >
                                      Sign Out
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        ) : (
                          <div className="text-white">
                            <button
                              onClick={() => signIn()}
                              className="bg-[#38A8AE] py-1 px-2 rounded"
                            >
                              Sign In
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 rounded-b-md transition-colors duration-500 border-b">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-[#1aa1b7] text-white"
                      : "text-gray-700 hover:bg-gray-100",
                    "block px-3 py-2 rounded-md text-base font-akrobat font-extralight"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
